.agent-info {
  display: grid;
  grid-template-columns: 350px auto;
  grid-gap: 1.75rem;
  align-items: flex-start;

  @media (min-width: 1399px) {
    grid-template-columns: 400px auto;
  }

  @media screen and (max-width: 1200px) {
    grid-template-columns: 1fr;
  }

  &__content {
    overflow-x: auto;
  }

  &__card-row {
    margin-bottom: rem(8px);
    &__label {
      line-height: rem(24px);
    }
    p {
      font-weight: 500;
    }
  }
}

.select-criterion-modal {
  &__content {
    max-height: 75vh;
    overflow: auto;
    .quill-editor {
      border-left: 1px solid $border-color;
      border-right: 1px solid $border-color;
      border-bottom: 1px solid $border-color;
    }
  }
}
