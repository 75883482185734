.chips {
  display: inline-flex;
  align-items: center;
  border: 1px solid $light-grey-400;
  padding: rem(4px 12px 4px 7px);
  border-radius: rem(25px);

  &__label {
    line-height: rem(24px);
    padding: rem(0 6px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  &__icon {
    display: inline-flex;
    cursor: pointer;
    margin-top: 1px;
    svg {
      color: $dark-grey-400;
      width: rem(15px);
      height: rem(15px);
    }
  }
}
