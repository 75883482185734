.details-by-type {
  flex-grow: 1;

  width: calc(100% - 480px);
  max-width: 900px;
  @media screen and (max-width: 1440px) {
    max-width: calc(100% - 340px);
  }
  @media screen and (max-width: 1199px) {
    max-width: 100%;
  }
}
.re-audit {
  max-width: 900px;
  width: calc(100% - 480px);
  flex: 1;
}

.resume {
  max-width: 410px;
  margin-left: 24px;
  width: 100%;
  @media screen and (max-width: 1440px) {
    max-width: 340px;
    .control-card {
      & > div > div {
        flex-direction: column;
      }
    }
  }
  @media screen and (max-width: 1199px) {
    .control-card {
      & > div > div {
        flex-direction: row;
      }
    }
    min-width: 100%;
    margin-left: 0;
    margin-bottom: 24px;
    order: -1;
  }
  h2 {
    font-size: rem(18px);
    line-height: rem(24px);
  }
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  li {
    font-size: rem(14px);
    line-height: rem(24px);
    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }
}
