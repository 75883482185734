.table-header {
  &__search {
    max-width: 248px;
  }
}

.applications__select-status {
  position: relative;
  span {
    font-weight: 600;
    font-size: 14px;
    white-space: nowrap;
  }
}

.applications-criteria {
  &__list-item {
    display: block;
    padding: 1rem;
    border: 1px solid $light-grey-200;
    border-radius: $border-radius;
    margin: 0.5rem 0;
    color: black;

    transition: 0.2s;
    outline: none;

    &:hover {
      background-color: $light-grey-200;
    }
    &:focus {
      border-color: $light-grey-400;
    }
    &:active {
      background-color: $light-grey-300;
    }
    &--disabled {
      background-color: $light-grey-100;
      pointer-events: none;
      .badge {
        &.secondary {
          color: $secondary-color;
        }
      }
      h4 {
        color: $secondary-color;
      }
    }
  }
}

.applications-subcriteria {
  &__list-item {
    position: relative;
    display: block;
    padding: 1rem;
    border: 1px solid transparent;
    border-bottom: 1px solid $light-grey-200;
    color: black;
    cursor: pointer;

    transition: 0.2s;
    outline: none;

    &:hover {
      background-color: $light-grey-200;
    }
    &:focus {
      border-color: $light-grey-400;
    }
    &:active {
      background-color: $light-grey-300;
    }

    &-wrapper {
      position: relative;
    }

    &-side {
      display: inline-block;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 1rem;
    }

    &::before {
      content: "";
      position: absolute;
      bottom: -1px;
      left: 0;
      height: 2px;
      background-color: $primary-color;
      animation: applications-subcriteria-progress-animation 0.5s forwards;
    }
  }
}

@keyframes applications-subcriteria-progress-animation {
  from {
    width: 0;
  }
  to {
    width: calc(var(--progress) * 1%);
  }
}
.control-card {
  .ebs-button__wrapper {
    flex-grow: 1;
    max-width: calc(50% - 0.25rem);
  }
}

.disabled-radio {
  pointer-events: none;
  textarea {
    resize: none;
  }
}

.control-modal-header {
  border-bottom: 1px solid $border-color;
}

.highlight-row {
  background-color: $light-grey-100;
}

.assign-user {
  background-color: $assign;
}
