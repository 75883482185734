.examination-card {
  &__risk-select-field {
    margin: 0;

    .ebs-select {
      min-height: 2.15rem;
    }

    .ebs-form__field__footer {
      display: none;
    }
  }
}
