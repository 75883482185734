.statistics-card {
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  border-radius: 6px;
  padding: 24px;
  min-height: 164px;
  height: 100%;
  width: 100%;
  flex-direction: column;
  &__box {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
  &__title {
    color: $text-color;
    font-weight: 400;
    font-size: 14px;
    display: flex;
    &:before {
      content: "";
      width: 8px;
      height: 8px;
      border-radius: 5px;
      background-color: var(--background-color);
      display: inline-block;
      margin-right: 5px;
      margin-bottom: 1px;
      min-width: 8px;
      margin-top: 6px;
    }
  }
  &__value {
    font-size: rem(36px);
    font-weight: 700;
    line-height: 1;
    color: var(--color);
  }
  &__progress-box {
    display: flex;
    align-items: flex-end;
  }
}
