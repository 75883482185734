.authorizations {
  &__dropdown-wrapper {
    padding: 0 rem(15px);
  }
  &__overlay {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 3;
  }

  &__surface {
    width: 250px;
    background-color: white;
    border-radius: $border-radius;
    overflow: hidden;

    position: absolute;
    top: calc(100% + 0.5rem);
    right: 0;
    opacity: 0;
    pointer-events: none;
    transform: scaleY(0);
    z-index: 4;

    &--open {
      opacity: 1;
      transform: initial;
      pointer-events: initial;
    }
  }

  &__container {
    max-height: 80vh;
    display: flex;
    flex-direction: column;
    .collapse__header {
      padding-left: 15px;
      padding-right: 15px;
      &:before {
        width: 100%;
        left: 0;
      }
      &:not(:last-child) {
        border-bottom: 1px solid $light-grey-200;
      }

      &--disabled {
        background-color: rgba(0, 75, 90, 0.03);
        &:hover:before {
          background: none;
        }
      }
    }
  }

  &__title {
    padding: 7px 15px;
    overflow: hidden;
    width: 100%;
    border: none;
    border-radius: 0;
    &:hover:not(.ebs-button--disabled) {
      background-color: rgba(0, 75, 90, 0.03);
    }

    &:not(:last-child) {
      border-bottom: 1px solid $light-grey-200;
    }
    &.ebs-button--disabled {
      background-color: rgba(0, 75, 90, 0.03);
      .ebs-button {
        color: $grey-100;
      }
    }
    .ebs-button {
      text-align: left;
      color: $grey-500;
      padding: 0;
      font-weight: 600;
      margin: 0;
      border: 0;
      &--disabled {
        background-color: rgba(0, 75, 90, 0.03);
      }
    }
  }

  &__item {
    padding: 0;
    margin: 0;
    width: 100%;
    button {
      text-align: left;
      color: $text-color;
    }

    &:hover {
      border-color: $light-grey-200;
      background-color: $light-grey-300;
      border-radius: 3px;
      color: black;
    }
  }
  svg {
    display: none;
  }
}
