.audit-order-details {
  position: relative;
  max-width: 800px;

  &:not(:last-of-type):before {
    position: absolute;
  }
  &:not(:last-of-type) {
    opacity: 0.55;
  }
}
