.tab-label {
  margin: 0 10px 0 0;
  transition: 0.2s ease-in-out;
  background: none;
  border-radius: 6px;
  padding: rem(4px 12px);
  color: $text-color;
  cursor: pointer;
  &:hover {
    background: $light-grey-300;
    border-radius: 6px;
  }
  &--active {
    background: $light-grey-300;
    border-radius: 6px;
    color: $text-color;
  }
}
