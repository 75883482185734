.chat-conversation {
  flex: 1;
  background-color: white;
  border-radius: $border-radius;
  border: 1px solid $light-grey-400;
  margin: $chat-overflow-padding-fix;
  height: 100%;
  display: flex;
  flex-direction: column;

  &__no-selected-container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__no-selected-message {
    padding: 1rem 2rem;
    border-radius: $border-radius;
    border: 1px solid $light-grey-400;
  }

  &__title {
    border-bottom: 1px solid $light-grey-400;
    padding: 0.5rem 1rem 0.5rem 0.5rem;
  }

  &__content-row {
    display: flex;
    align-items: stretch;
    flex: 1;
  }

  &__messages-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding: 1rem;
  }

  &__details {
    max-width: 250px;
    border-left: 1px solid $light-grey-400;
    padding: 1rem;
  }
}

.chat-conversation-link {
  $self: #{&};

  display: block;
  padding: 1rem;
  margin-right: 0.25rem;
  border-radius: $border-radius;

  border: 1px solid transparent;

  outline: none;
  background-color: white;
  color: black;

  transition: 0.2s;

  &__title {
    flex: 1;
  }
  &__subtitle {
    color: $grey-200;
    font-size: 0.8rem;
  }
  &__last-message {
    color: $grey-300;
    word-break: break-word;
    max-height: 4.5rem;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
  &__date {
    font-size: 0.8rem;
    color: $grey-500;
  }

  &:hover:not(&--active) {
    border-color: $light-grey-300;
  }
  &:active:not(&--active) {
    background-color: $light-grey-100;
  }
  &:focus:not(&--active) {
    border-color: $grey-100;
  }

  &--active {
    border-color: $primary-color;
  }
}
