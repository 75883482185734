// For the box shadow of buttons
$chat-overflow-padding-fix: 3px;

.chat {
  &__layout {
    height: 80vh;
    display: flex;
    align-items: stretch;
  }

  &__conversation-list-container {
    padding: $chat-overflow-padding-fix;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
    width: 320px;
    margin-right: 1rem;
  }

  // &__conversation-search {
  // }

  &__conversation-list {
    flex: 1;
    // height: 100%;
    overflow: auto;
    flex-direction: column;
    display: flex;
    margin-top: 1rem;
  }
}

@import "chat-converation";
@import "chat-messages";
