.chat-messages {
  &__list {
    flex: 1;
    height: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column-reverse;
    margin-bottom: 1rem;
    padding-right: 0.5rem;
  }
}
