.matrix {
  &__level-title {
    font-weight: 600;
    font-size: 12px;
    align-items: center;
    display: flex;
    justify-content: center;
  }
  &__top {
    &__header {
      display: grid;
      grid-template-columns: 79px 1fr;
      text-align: center;
      &__wrapper {
        border: 1px solid #dde2e4;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: 40px 40px;
      }
      &__title {
        grid-column: 1 / span 3;
        color: #6e7c87;
        border-bottom: 1px solid #dde2e4;
        align-items: center;
        display: flex;
        justify-content: center;
      }
      &__empty-cell {
        grid-row: 1 / span 2;
      }
    }
  }
  &__left {
    &__header {
      display: grid;
      grid-template-columns: 1fr 1fr;
      text-align: center;
      align-items: center;
      border: 1px solid #dde2e4;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      &__title {
        grid-row: 1 / span 3;
        color: #6e7c87;
        align-items: center;
        display: flex;
        justify-content: center;
        height: 100%;
        border-right: 1px solid #dde2e4;
      }
    }
  }

  &__content {
    display: grid;
    grid-template-columns: 80px auto;
  }
  &__blocks {
    display: grid;
    grid-template-rows: 90px 90px 90px;
    grid-template-columns: 1fr 1fr 1fr;
    font-weight: bold;

    &__block {
      display: flex;
      align-items: center;
      justify-content: center;
      border-right: 1px solid #dde2e4;
      border-bottom: 1px solid #dde2e4;
      box-shadow: inset 0px 0px 0px 3px #fff;
    }
  }
}
.matrix__left__header {
  .matrix__level-title {
    height: 89px;
  }
}
.text-vertical {
  transform: rotate(180deg);
  writing-mode: vertical-lr;
}

//colors
.color-light-green {
  background-color: #bbfbd0;
  color: #119c2b;
}
.color-green {
  background-color: #67e48b;
  color: #119c2b;
}
.color-light-yellow {
  background-color: #fef99f;
  color: #d29404;
}
.color-yellow {
  background-color: #fbec7e;
  color: #d29404;
}
.color-light-red {
  background-color: #fed6cd;
  color: #cc0905;
}
.color-red {
  background-color: #fa8d7f;
  color: #cc0905;
}
